<template>
    <div class="">
	      <div style="display: flex;justify-content: space-between;font-size: 20px">
			   <div  class="headerContainer">
				   <img src="../../assets/logo.png"  width="40px" alt="">
				   <div style="display: inline-block;margin-left: 10px;font-weight: bold">消防安全检查-AI人工智能图审系统</div>
				   <div> &nbsp;|&nbsp; 审查场所申报列表</div>
			   </div>
		      <div style="display: flex;justify-content: space-between;align-items: center">
			      <div style="margin-right: 20px">账号：{{this.name}}</div>
			      <div>
				      <el-button type="primary" @click="logout">登出</el-button>
			      </div>
		      </div>
	      </div>
	      <div class="parent" style="font-size: 20px">
		      <div class='child'>
			      <div class="" style="font-weight: bold;margin-left: 20px;padding-bottom:8px ">
				      审查场所申报列表
			      </div>
		      </div>
	      </div>
        <div style="width:80%;margin: 0 auto">
	        <el-row>
		        <el-col>
			        <el-form :inline='true'>
				        <el-form-item label="">
					        <el-input v-model="filters.keyWord" @input="(e) => (filters.keyWord = utils().validForbid(e))" placeholder="编号/名称" clearable></el-input>
				        </el-form-item>
				        <el-form-item>
					        <el-button type="primary" @click="isDialog" ><div style="font-size: 20px ;">查询</div></el-button>
				        </el-form-item>
				        <el-form-item>
					        <el-button type="primary" @click="addProject" ><div style="font-size: 20px;">消防安全检查申报（非告知承诺方式）</div></el-button>
				        </el-form-item>
				        <el-form-item>
					        <el-button type="primary" @click="addProjectOther" style="font-size: 20px" >消防安全检查基本信息登记（告知承诺方式）</el-button>
				        </el-form-item>
			        </el-form>
		        </el-col>
	        </el-row>
        </div>
        <div style=" width:80%;margin: 0 auto;height: 120vh">
	        <!-- 列表 -->
	        <el-table
			        :data="tableData"
			        border
			        style="width: 100%"
			        v-loading="listLoading"
			        ref="multipleTable"
			        @current-change='currentChange'
			        :highlight-current-row='true'
	        >
		        <el-table-column type='index' style="font-size: 16px"  label="序号" width='80' :index='indexMethod' align="center">
			        <template slot="header" >
				       <span style="font-size: 20px"> 序号</span>
			        </template>
			        <template slot-scope="scope">
				        <div style="font-size: 16px">{{scope.$index+1}}</div>
			        </template>
		        </el-table-column>
		        <!-- <el-table-column type="selection" width="45"></el-table-column> -->
<!--		        <el-table-column prop="projectNo" label="项目编号" align="center" show-overflow-tooltip width="160"></el-table-column>-->
		        <el-table-column prop="areaName" style="font-size: 16px" label="地区" align="center" show-overflow-tooltip width="180">
			        <template slot="header" >
				        <div style="font-size: 20px;padding: 10px"> 地区</div>
			        </template>
			        <template slot-scope="scope">
				        <div style="font-size: 16px">{{scope.row.areaName}}</div>
			        </template>
		        </el-table-column>
		        <el-table-column prop="projectName" style="font-size: 16px" label="场所名称" align="center" show-overflow-tooltip width="">
			        <template slot="header" >
				        <div style="font-size: 20px;padding: 10px"> 场所名称</div>
			        </template>
			        <template slot-scope="scope">
				        <div style="font-size: 16px">{{scope.row.projectName.split("\n")[0]}}</div>
			        </template>
		        </el-table-column>
		        
		        <el-table-column prop="projectAddress" style="font-size: 16px" label="场所地址" align="center" show-overflow-tooltip width="">
			        <template slot="header" >
				        <div style="font-size: 20px;padding: 10px"> 场所地址</div>
			        </template>
			        <template slot-scope="scope">
				        <div style="font-size: 16px">{{scope.row.projectAddress}}</div>
			        </template>
		        </el-table-column>
		        <el-table-column prop="projectArea" style="font-size: 16px" label="场所面积" align="center" show-overflow-tooltip width="125">
			        <template slot="header" >
				        <div style="font-size: 20px;padding: 10px 0"> 场所面积</div>
			        </template>
			        <template slot-scope="scope">
				        <div style="font-size: 16px">{{scope.row.projectArea+'平方米'}}</div>
			        </template>
		        </el-table-column>
		        <el-table-column prop="" label="申报状态" style="font-size: 16px" align="center" show-overflow-tooltip width="130">
			        <template slot="header" >
				        <div style="font-size: 20px;padding: 10px"> 申报状态</div>
			        </template>
			        <template slot-scope="scope">
				        <el-tag v-if="scope.row.ddIdProjectStatus===0"  style="font-size: 16px" type="danger">未提交</el-tag>
				        <el-tag v-else-if="scope.row.ddIdProjectStatus===10"  style="font-size: 16px" type="warning">整改中</el-tag>
				        <el-tag v-else-if="scope.row.ddIdProjectStatus===55"  style="font-size: 16px" type="warning">已结案</el-tag>
				        <el-tag v-else style="font-size: 16px" type="success">已提交</el-tag>
			        </template>
		        </el-table-column>
		        <el-table-column prop="" label="申报状态" style="font-size: 16px" align="center" show-overflow-tooltip width="130">
			        <template slot="header" >
				        <div style="font-size: 20px;padding: 10px">报告状态</div>
			        </template>
			        <template slot-scope="scope">
				        <el-tag v-if="scope.row.reportPassStatus"  style="font-size: 16px" type="success">合格</el-tag>
				        <el-tag v-else style="font-size: 16px" type="danger">不合格</el-tag>
			        </template>
		        </el-table-column>
		        <el-table-column prop="projectNo" label="" width='130' align="center" show-overflow-tooltip >
			        <template slot-scope="scope">
				        <el-button v-if="scope.row.ddIdProjectType===415" type="primary" size="mini" style="font-size: 16px"  @click="projectRegister(scope.row)">修改登记表</el-button>
<!--				        <el-button v-if="scope.row.ddIdProjectType===457" type="primary" size="medium" style="font-size: 16px" :disabled="scope.row.ddIdProjectStatus !==0" @click="projectRegister(scope.row)">修改申报表</el-button>-->
				        <el-button v-if="scope.row.ddIdProjectType===457" type="primary" size="mini" style="font-size: 16px"  @click="projectRegister(scope.row)">修改申报表</el-button>
			        </template>
		        </el-table-column>
		        
		        <el-table-column prop="projectNo" label="" align="center" show-overflow-tooltip width="123px">
			        <template slot-scope="scope">
				        <el-button type="primary" size="mini" style="font-size: 16px"  @click="drawingUpload(scope.row)">上传图纸</el-button>
			        </template>
		        </el-table-column>
		        <el-table-column prop="projectNo" label="" align="center" show-overflow-tooltip width="123px">
			        <template slot-scope="scope">
				        <div v-if="scope.row.ddIdProjectStatus == 55 || scope.row.ddIdProjectStatus == 140|| scope.row.ddIdProjectStatus == 141">
					        <el-button type="primary" size="mini" style="font-size: 16px" @click="redeclaration(scope.row)">重新申报</el-button>
				        </div>
				        <div v-else>
					        <el-button type="primary" size="mini" style="font-size: 16px" :disabled="scope.row.ddIdProjectStatus !==0&&scope.row.ddIdProjectStatus !==10" @click="submitReviewVal(scope.row)">提交审查</el-button>
				        </div>
			        </template>
		        </el-table-column>
		        <el-table-column label="整改意见"  align="center" width="150">
			        <template slot-scope='scope'>
				        <el-button type="text" v-if="scope.row.ddIdProjectStatus ===10" @click="viewsLeaveMessage(scope.row)" >查看</el-button>
			        </template>
		        </el-table-column>
	        </el-table>
	        <!--底部工具条-->
	        <el-col :span="24" class="pageBar">
		        <el-pagination
				        @size-change="handleSizeChange"
				        @current-change="handleCurrentChange"
				        :current-page="pages.pageIndex"
				        :page-sizes="pages.pageArr"
				        :page-size="pages.pageSize"
				        layout="total, sizes, prev, pager, next, jumper"
				        :total="pages.dataCount">
		        </el-pagination>
	        </el-col>
        </div>
	    <PageComponents/>
	    
	    <div >
		    <el-footer style="text-align: center; background-color: #EAEBEB;position: sticky; padding: 10px; bottom: 0; left: 0; width: 100%;">
			    <div style="display: flex; align-items: center;justify-content: space-around; width: 80%;margin-left: 10%">
				    <div style=" display: flex;align-items: center">
					    <div>技术支持：</div>
					    <img src="../../assets/heibai.png"  style="height: 40px" alt="">
				    </div>
				    <div>
					    Copyright © 2014-2024
				    </div>
				    <div>
					    沪ICP备2022031715号
				    </div>
				    <!--				        <div>-->
				    <!--					        赣公网安备 3607012000119号-->
				    <!--				        </div>-->
			    </div>
		    
		    </el-footer>
	    </div>
	    <el-dialog
			    :title="isOr===1?'消防安全检查申报（非告知承诺方式）':'消防安全检查基本信息登记（告知承诺方式）'"
			    :visible.sync="projectRegisterDialog"
			    v-model="projectRegisterDialog"
			    :close-on-click-modal="false"
			    width='1200px'
			    :center="true"
	    >
		    <el-form ref="form"  :model="form" label-position="left" label-width="160px" style="width: 80%;margin-left: 10%">
			    <el-form-item label="地区" required  style="width: 100%">
				    <el-select v-model="form.queryValue.first" @change="selectFirst" clearable placeholder="请选择省">
					    <el-option
							    v-for="item in options"
							    :key="item.code"
							    :label="item.name"
							    :value="item.code">
					    </el-option>
				    </el-select>
				    <el-select v-model="form.queryValue.second" @change="selectSecond" clearable placeholder="请选择市">
					    <el-option
							    v-for="item in options1"
							    :key="item.code"
							    :label="item.name"
							    :value="item.code">
					    </el-option>
				    </el-select>
				    <el-select v-model="form.queryValue.third" @change="selectThird" clearable placeholder="请选择区">
					    <el-option
							    v-for="item in options2"
							    :key="item.code"
							    :label="item.name"
							    :value="item.code">
					    </el-option>
				    </el-select>
			    </el-form-item>
			    <el-form-item label="场所名称" required style="width: 100%">
				    <el-input v-model="form.projectName" style="width: 100%" ></el-input>
			    </el-form-item>
			    <el-form-item label="场所地址" required style="width: 100%">
				    <el-input v-model="form.address" style="width: 100%" ></el-input>
			    </el-form-item>
			    <el-form-item label="统一社会信用代码" required style="width: 100%">
				    <el-input v-model="form.projectAddress" style="width: 100%" ></el-input>
			    </el-form-item>
			    <el-form-item label="营业执照" required>
				    <input type="file"  @change="handleFileUpload" />
				    <div v-for="child in fileList" class="fileListItem">
					    <div>
						    <el-button type="text" size="mini" >{{ child.name }}</el-button>
					    </div>
					    <div>
<!--						    <el-button type="text" size="mini" @click="downloadDrawing(child)">下载</el-button>-->
						    <el-button type="text" size="mini" @click="deleteDrawing(child)">删除</el-button>
					    </div>
				    </div>
			    </el-form-item>
		    </el-form>
		    <div slot="footer" class="dialog-footer">
			    <el-button @click="projectRegisterDialog = false">取 消</el-button>
			    <el-button type="primary" @click="saveNext">保 存</el-button>
		    </div>
	    </el-dialog>
        <el-dialog
            :title="isOr===1?'消防安全检查申报（非告知承诺方式）':'消防安全检查基本信息登记（告知承诺方式）'"
            :visible.sync="registerDialog"
            v-model="registerDialog"
            :close-on-click-modal="false"
            width='1200px'
            :center="true"
        >
	        <div style="padding-left:0;margin-left:-25px;background-color: #2d8cf0;height: 70px;width: 100%;position: absolute"></div>
	        
	        <div style="position: absolute; margin-top: 30px;z-index: 1;display: flex;justify-content: space-between;width: 80%;margin-left: 10%"  >
		        <div @click="activeFirst"  :class=" activeName === 'first'?'divButtonActive':'divButton' " >申报表</div>
		        <div style="width: 10%"></div>
		        <div @click="activeSecond"  :class=" activeName === 'second'?'divButtonActive':'divButton' " >上传图纸</div>
		        <div style="width: 10%"></div>
		        <div @click="activeThird"  :class="activeName === 'third'?'divButtonActive':'divButton'" >提交审查</div>
	        </div>
	        <div style="height: 90px"></div>
	        
<!--	        <el-radio-group  style="width:80%;margin-left:10%;display: flex;justify-content: space-between; margin-top: 27px" v-model="activeName">-->
<!--		        <el-radio-button  label="first" ><div style="font-size: 18px;width: 180px;border-bottom: none">填写申报表</div></el-radio-button>-->
<!--		        <div style="width: 10%"></div>-->
<!--		        <el-radio-button  label="second" ><div style="font-size: 18px;width: 180px;border-bottom: none">上传图纸</div></el-radio-button>-->
<!--		        <div style="width: 10%"></div>-->
<!--		        <el-radio-button  label="third" ><div style="font-size: 18px;width: 180px;border-bottom: none">提交审查</div></el-radio-button>-->
<!--	        </el-radio-group >-->
	        <div v-if="activeName == 'first'">
		        <WordTable @closeThisDialog="closeThisDialog" :isDisabled="needData&&needData.ddIdProjectStatus!==0?true:false" :isOr = "isOr" :querys="querys"  :areaList="areaList"  :projectId="needData ? needData.id : 0" :userInfo="userInfo" ></WordTable>
	        </div>
	        <div v-if="activeName == 'second'">
		        <ProjectView  :needData="needData" :isDisabled="needData&&needData.ddIdProjectStatus!==0&&needData.ddIdProjectStatus!==null?true:false"></ProjectView>
		        <el-button type="primary" style="margin: 5% 0 0 45%;font-size: 22px" @click="nextTo">下一步</el-button>
	        </div>
	        <div v-if="activeName == 'third'">
		        <div style="width: 55% ;margin: 5% auto  10%">
              <el-card>
                <div style="font-size: 28px;font-weight: bold">温馨提示：</div>
                <div style="font-size: 24px;margin-top: 5%">(1)提交审查前，请认真核对申报信息和图纸；</div>
                <div style="font-size: 24px;margin-top: 5%">(2)点击【提交审查】后所有填报信息均不可再修改；</div>
              </el-card>
              <el-button type="primary" style="width: 100%;margin-top: 5%;font-size: 22px" @click="submitReview">提交审查</el-button>
            </div>
	        </div>
        </el-dialog>
	    <el-dialog
			    title="提交审查"
			    :visible.sync="submitDialog"
			    v-model="submitDialog"
			    :close-on-click-modal="false"
			    width='60%'
			    :center = 'true'
	    >
		    <div style="width: 55% ;margin: 5% auto  10%">
			    <el-card style="margin-bottom: 40px">
				    <div style="font-size: 28px;font-weight: bold">温馨提示：</div>
				    <div style="font-size: 24px;margin-top: 5%">(1)提交审查前，请认真核对申报信息和图纸；</div>
				    <div style="font-size: 24px;margin-top: 5%">(2)点击【提交审查】后所有填报信息均不可再修改；</div>
			    </el-card>
			    <el-form v-if="!!(needData&&needData.ddIdProjectStatus=== 10)">
				    <el-form-item  label="选择重新申报的原因：" style="font-size: 22px" required>
						    <el-select v-model="opinionId"  style="width: 72%" placeholder="请选择">
							    <el-option
									    v-for="item in optionsList"
									    :key="item.id"
									   
									    :label="item.value"
									    :value="item.id">
							    </el-option>
						    </el-select>
				    </el-form-item>
			    </el-form>
			    <el-button type="primary" style="width: 100%;margin-top: 5%;font-size: 22px" @click="submitReview">提交审查</el-button>
		    </div>
	    </el-dialog>
	    
	    <el-dialog
			    title="提交审查"
			    :visible.sync="confirmDialog"
			    v-model="confirmDialog"
			    :close-on-click-modal="false"
			    width='60%'
			    :center = 'true'
	    >
		    <div style="width: 55% ;margin: 5% auto  10%">
				    
					    <div style="font-size: 28px;font-weight: bold">温馨提示：</div>
			    <div style="font-size: 24px;margin-top: 5%">带有红色标记“<span style="color: red">*</span>”的为必传项，请上传完整后再提交。</div>
		    </div>
		    <el-button type="primary" style="width: 120px;margin-top: 5%;margin-left: 45% ;font-size: 22px" @click="confirmToSheet(needData)">确认</el-button>
		    
	    </el-dialog>
	    
	    <el-dialog
			    title="消防安全检查申报"
			    :visible.sync="declarationDialog"
			    v-model="declarationDialog"
			    :close-on-click-modal="false"
			    width='1200px'
			    :center = 'true'
	    >
		    <WordTable @closeThisDialog="closeThisDialog"  :isDisabled="!!(needData&&needData.ddIdProjectStatus!==0&&needData.ddIdProjectStatus!==10)" :areaList="areaList" :isOr = "isOr" :needData="needData?needData:null" :projectId="needData ? needData.id : 0" :userInfo="userInfo" ></WordTable>
<!--		    <WordTable @closeThisDialog="closeThisDialog" :isDisabled="needData&&needData.ddIdProjectStatus!==0?true:false" :isOr = "isOr" :querys="querys"  :areaList="areaList"  :projectId="needData ? needData.id : 0" :userInfo="userInfo" ></WordTable>-->
	    </el-dialog>
	    <el-dialog
			    title="上传图纸"
			    :visible.sync="drawingDialog"
			    v-model="drawingDialog"
			    :close-on-click-modal="false"
			    width='60%'
	    >
		    <ProjectView @activeChange="activeChange" :isDisabled="!!(needData&&needData.ddIdProjectStatus!=0&&needData.ddIdProjectStatus != 10&&needData.ddIdProjectStatus!==null)" :needData="needData"></ProjectView>
		    <div  slot="footer" class="dialog-footer">
			    <el-button type="primary" @click.native="closeD">保存</el-button>
		    </div>
	    </el-dialog>
	    
	    <!-- 整改记录 -->
	    <el-dialog
			    title="整改意见"
			    :visible.sync="messageViewDialog"
			    v-model="messageViewDialog"
			    :close-on-click-modal="false"
			    width='50%'
	    >
<!--		    <el-table :data="messageArr"-->
<!--		              style="width: 100%"-->
<!--		              :highlight-current-row='true'-->
<!--		              border-->
<!--		    >-->
<!--			    <el-table-column prop="content" label="整改内容" width="">-->
<!--				    <template slot-scope="scope">-->
<!--					    <el-input type="textarea" :rows="2" style="width: 100%" v-model="scope.row.content" readonly></el-input>-->
<!--				    </template>-->
<!--			    </el-table-column>-->
<!--			    <el-table-column prop="createTime" label="时间" width="120">-->
<!--				    <template slot-scope="scope">-->
<!--					    {{scope.row.createTime.split('T')[0]}}-->
<!--				    </template>-->
<!--			    </el-table-column>-->
<!--			    <el-table-column prop="userName" label="附件" width="350px">-->
<!--				    <template slot-scope="scope">-->
<!--					    <div v-for="item in JSON.parse(scope.row.fileUrl)">-->
<!--						    <el-button type="text" @click="showMessageFile(item)" style="margin-right: 5px">{{item.sourceName}}</el-button>-->
<!--						    <br>-->
<!--					    </div>-->
<!--				    </template>-->
<!--			    </el-table-column>-->
<!--		    </el-table>-->
		    <el-form label-width="120px" :model="form" :rules="rules" ref="form">
			    <el-form-item label="整改内容" prop="content">
				    <el-input type="textarea" :rows="4" style="width: 80%" v-model="messageContent" readonly></el-input>
			    </el-form-item>
			    <el-form-item label="附件" prop="fileUrl">
            <div v-for="item in messageArr">
              <el-button type="text" @click="showMessageFile(item)" style="margin-right: 5px">{{item.sourceName}}</el-button>
            </div>
			    </el-form-item>
		    </el-form>
	    </el-dialog>
    </div>
</template>

<script>
import util from "../../../util/date";
import WordTable from "./wordTable";
import PageComponents from "../XieYi/pagecomponents.vue";
import ObsClient from "esdk-obs-browserjs/src/obs";
import {
	getProjectList,
	getAccountInfo,
	getContractList,
	submitProject,
	getApplicationByProjectId,
	getProjectFileInfo,
	getAreaDictionaryListWithCode,
	deleteProjectFile,
	generateTemporaryUrlByFileName,
	generateTemporaryUrl, getContractByAreaCode, isAllowApplyProject, reApplyProject, getByNamespace,
} from '../../api/api'
import ProjectView from "../drProjectManager/projectView.vue";
import { validPhone, validString } from '../../../util/validate'
import {utils} from "xlsx";
export default {
    components:{WordTable,ProjectView,PageComponents},
    data() {
        //验证手机号
        let isPhoneNumberNum = (rule, value, callback) => {
            if (!validPhone(value)) {
                return callback(new Error("请输入正确的手机号"));
            } else {
                callback();
            }
        };
        return {
	        optionsList:[],
	        opinionId:undefined,
	        messageArr:[],
	        messageContent:'',
	        messageViewDialog : false,
					name:undefined,
					form:{
						projectAddress:undefined,
						url:undefined,
						projectName: undefined,
						address: undefined,
						queryValue:{
							first:undefined,
							second:undefined,
							third:undefined,
						},
					},
					rules: {
						name: [
							{ required: true, message: '请输入地区', trigger: 'blur' },
							{ validator: validString, trigger: 'blur' }
						],
						phone: [
							{ required: true, message: '请输入场所名称', trigger: 'blur' },
							{ validator: isPhoneNumberNum, trigger: 'blur' }
						],
						email: [
							{ required: true, message: '请输入统一社会信用代码', trigger: 'blur'}
						],
						address: [
							{ required: true, message: '请输入场所地址', trigger: 'blur' }
						],
					},
					areaList:[],
	        userInfo: undefined,
	        needData: '',
	        filters: {
		        keyWord: '',
	        },
	        listLoading: false,
	        //关于分页的obj
	        pages: {
		        pageSize: 20,//默认每页条数
		        pageArr: [10, 20, 30, 40],//默认每页条数选择
		        pageIndex: 1, //默认进入页
		        dataCount: 20, //默认总页数
	        },
	        projectRegisterDialog:false,
	        registerDialog:false,
	        tableData: [],
	        activeName:'first',
	        declarationDialog:false,//申报
	        drawingDialog:false,//图纸上传
	        isTrue:false,
	        placeJsonData : [],
	        nameList:[],
	        submitDialog:false,//提交审查
	        confirmDialog:false,//提交审查
	        drawingList:[],
	        fileList: [],
	        uploading:false,
	        
	        options:[],
	        options1:[],
	        options2:[],
	        query:{
						level: 1,
		        name: '',
		        parentCode:undefined,
	        },
	        Bucket: process.env.VUE_APP_BUCKET_NAME,  // 存储桶名称
	        obsClient: null,  // 初始化OBS客户端
	        Area: process.env.VUE_APP_SERVER_ACCESS_AREA_A,    // 区域名称
	        keyValue:'',
	        drawingUrl:'',
	        querys:undefined,
	        isOr:0,
	        redeclarationDialog:false,
        }
    },
    methods: {
			//根据命名空间获取数据字典
	    getDictionaryList(namespace,row) {
		    let params = {
			    namespace: namespace
		    }
		    getByNamespace(params).then(res => {
			    if (res.data.success) {
				    let data = res.data.response;
				    if (row.reportPassStatus ) {
					    this.optionsList = data
					    console.log(data,'namespace')
				    } else {
							this.optionsList = data.filter ( item => item.id !== 461)
				    }
			    }
		    })
	    },
			//重新申报
	    redeclaration(row){
				
				this.$confirm('您现在可以修改申请表和图纸，重新提交审查！', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let params = {
						opinionId:0,
						projectId: this.needData.id
					}
					reApplyProject(params).then(res=>{
						if(res.data.success){
							this.$message({
								type: 'success',
								message: res.data.message,
							});
							this.getListData();
						}else{
							this.$message({
								type: 'error',
								message: res.data.message,
							});
						}
					})
				})
	    },
	    //访问整改文件
	    showMessageFile(row){
		    let params = {
			    fileName: row.webupurl
		    }
		    generateTemporaryUrlByFileName(params).then(res => {
			    window.open(res.data);
			    // console.log(res)
		    })
	    },
			//整改记录
	    viewsLeaveMessage(row) {
		    console.log(row,'row')
				if (row.ddIdProjectStatus !== 10){
					this.$message.error('项目不在整改中！')
					return
				}
		    this.messageArr =  JSON.parse(row.messageFileUrl)
		    this.messageContent = row.messageContent
		    console.log(this.messageArr,'this.messageArr')
		    this.messageViewDialog = true
			    
	    },
			
			activeFirst(){
				this.activeName='first'
			},
	    activeSecond(){
		    this.activeName='second'
	    },
	    activeThird(){
		    this.activeName='third'
	    },
	    //下载图纸
	    downloadDrawing(child){
		    console.log(child,'chileddddddd')
		    let params = {
			    fileId:child.id,
			    projectId:this.needData.id,
		    }
		    generateTemporaryUrl(params).then(res=>{
			    if(res.data.success){
				    console.log(res,'res2222222')
				    window.open(res.data.response.url);
			    }
		    })
	    },
	    deleteDrawing(child) {
		    const index = this.fileList.indexOf(child);
		    if (index !== -1) {
			    this.fileList.splice(index, 1);
		    }
	    },
	    selectFirst(){
				this.options1 = []
				this.form.queryValue.second = undefined
				this.form.queryValue.third = undefined
				this.getProvinceListfirst()
			},
	    selectSecond(){
				this.form.queryValue.third = undefined;
				this.options2= []
				this.getProvinceListSecond()
	    },
	    selectThird(){
				
	    },
	    handleChange(file, fileList) {
		    this.fileList = fileList.slice(-3);
	    },
	    handlePreview(file) {
		    console.log(file,'handlePreview');
	    },
	    handleRemove(file, fileList) {
		    console.log('this.fileList', fileList);
	    },
	    // 上传营业执照
	    handleFileUpload(event) {
			    console.log(event,'event')
		    if (this.fileList&&this.fileList.length>0){
					return this.$message.error('只能上传一张！');
		    }
			    const file = event.target.files[0] // 获取选择的文件
			    console.log(file,'file')
			    if (file) {
				    const Key = 'businessLicense'+'/'+this.userInfo.phoneNumber+'/'+ file.name; // 使用文件名作为上传的Key
				    this.uploadFile(file, Key); // 调用上传方法，并传递额外参数 this.uploadFile(file, Key, additionalData); // 调用上传方法，并传递额外参数
				    console.log(Key,'Key')
			    }
	    },
	    // 上传文件方法
	    uploadFile(file, Key) {
		    const loading = this.$loading({
			    lock: true,
			    text: '上传中...',
			    spinner: 'el-icon-loading',
			    background: 'rgba(0, 0, 0, 0.7)'
		    });
		    this.obsClient = new ObsClient({
			    // 这里配置你的OBS客户端参数
			    access_key_id: process.env.VUE_APP_ACCESS_KEY_ID,//测试
			    secret_access_key: process.env.VUE_APP_SECRET_ACCESS_KEY,//
			    server: process.env.VUE_APP_SERVER_ACCESS_AREA
		    });
		    console.log(this.obsClient,'obsClient')
		    this.form.businessLicense =Key;
		    this.uploading = true; // 设置上传状态为true
		    this.obsClient.putObject({
			    Bucket: this.Bucket,
			    Key,
			    SourceFile: file,
		    }, (err, result) => {
			    this.uploading = false; // 设置上传状态为false
			    if (err) {
				    loading.close();
				    console.error('上传出错:', err); // 打印错误信息
			    } else {
				    loading.close();
						this.fileList.push({
							name: file.name,
							Key: Key,
						})
				    console.log('上传成功:', result); // 打印上传结果
				    const url = `https://${this.Bucket}.obs.${this.Area}/${Key}`;
				    console.log('文件URL:', url); // 打印文件URL
				    this.drawingUrl = Key;
			    }
		    });
	    },
			
			
			
			
	    logout(){
		    this.$confirm("确认退出吗?", "提示", {
			    //type: 'warning'
		    }).then(() => {
					    window.localStorage.removeItem("user");
					    window.localStorage.removeItem("Token");
					    window.localStorage.removeItem("TokenExpire");
					    window.localStorage.removeItem("NavigationBar");
					    window.localStorage.removeItem("refreshtime");
					    window.localStorage.removeItem("router");
					    window.localStorage.removeItem("expiresIn");
					    window.localStorage.removeItem("formData");
					    this.$router.push("/login");
					    window.location.reload();
				    })
				    .catch(() => {});
	    },
	    nextTo(){
				this.activeName='third'
			},
	    closeD(){
		    this.drawingDialog = false
		    // this.getDataList()
	    },
	    confirmToSheet(row){
				this.confirmDialog = false;
				this.needData = row
				this.drawingDialog=true
	    },
			//提交审查（val）
	    submitReviewVal(row){
				this.opinionId = undefined
				this.optionsList = []
		    if (row.ddIdProjectStatus === 10 ){
			    this.getDictionaryList('CommonService.WorkFlow.ReasonForRepeat',row)
		    }
		    setTimeout(()=>{
			    this.confirmDialog= false
			    this.submitDialog = true
		    },100)
		    // this.submitReview ()
	    },
			//图纸交互
	    activeChange(){
				this.
		    this.drawingDialog = false
	    },
			//上传图纸
	    drawingUpload(row){
				this.needData = row
				this.drawingDialog = true;
	    },
			//填写申报表
	    projectRegister(row){
				this.isOr = 0
				if (row.ddIdProjectType === 415){
					this.isOr = 2
				}
		    if (row.ddIdProjectType === 457){
			    this.isOr = 1
		    }
		    this.needData = row
				this.getUserInfo()
		    this.getAreaList()
		    setTimeout(()=>{
					if (row.ddIdProjectStatus !== 55 || row.ddIdProjectStatus !== 140 || row.ddIdProjectStatus !== 141){
						this.isTrue = true
					}
			    this.declarationDialog = true;
		    },100)
				
		    console.log(row,'row')
	    },
	    addDrawing(items) {
		    this.drawingList = [];
		    const filteredItems = []; // 新数组，用于存放对象
		    const processItems = (items) => {
			    items.forEach(item => {
				    if (item.filePathDxf !== null) {
					    filteredItems.push(item.fileTag);
				    }
				    if (item.childFiles && item.childFiles.length > 0) {
					    processItems(item.childFiles);
				    }
			    });
		    };
		    processItems(items);
		    // 你可以根据需求使用 filteredItems 数组，例如添加到 drawingList 中
		    this.drawingList.push(...filteredItems);
	    },
	    //获取图纸结构
	    getProjectFile(){
		    if (this.needData){
			    let params = {
				    projectId: this.needData.id
			    }
			    getProjectFileInfo(params).then(res => {
				    let result = res.data
				    if(result.success) {
					    this.fileInfo = result.response.files
					    console.log(this.fileInfo,'文件信息')
					    this.addDrawing(result.response.files)
					    this.drawingList = [...new Set (this.drawingList)]
					    console.log(this.drawingList,'radssdfsfd')
				    }
			    })
		    }
	    },
	    //获取申报表
	    getapplication () {
		    if (this.needData){
			    let params = {
				    projectId: this.needData.id
			    }
			    getApplicationByProjectId(params).then(res => {
				    let result = res.data
				    if (result.success) {
					    let resp = result.response
					    const res=resp.projectFireFacilitiesIds.split(',')
					    this.placeJsonData=res.map(str => parseInt(str, 10));
					    console.log(this.placeJsonData,'resp')
				    }
			    })
		    }
	    },
	    isSubarray(largerArray, smallerArray) {
		    return smallerArray.every(element => largerArray.includes(element));
	    },
	    submitReview(){
		    console.log(this.opinionId,'opinionId')
		    if (this.optionsList&&this.optionsList.length>0){
					if (!this.opinionId){
						this.$message({
							type: 'error',
							message: '请选择重新申报的原因!'
						})
						return
					}
		    }
		    if (this.needData){
			    this.getapplication()
			    setTimeout(() => {
				    this.getProjectFile()
			    },100)
			    setTimeout(() => {
				    if (this.needData){
					    this.$confirm('确认提交审查吗？', '提示', {
						    confirmButtonText: '确定',
						    cancelButtonText: '取消',
						    type: 'warning'
					    }).then(() => {
						    console.log(this.drawingList,'drawingList1111111')
						    console.log(this.placeJsonData,'drawingLi2222222')
						    if (!this.isSubarray(this.drawingList, this.placeJsonData)){
							    this.registerDialog = false;
							    this.confirmDialog = true;
							    this.submitDialog = false;
									this.projectRegisterDialog = false;
						    }else {
							    let params = {
										opinionId: this.opinionId,
								    projectId: this.needData.id,
							    }
							    submitProject(params).then(res => {
								    let result = res.data
								    if(result.success) {
									    this.projectRegisterDialog = false;
											this.registerDialog = false;
									    this.$message({
										    message: result.message,
										    type: 'success',
									    })
									    this.projectRegisterDialog = false;
									    this.submitDialog = false
									    this.confirmDialog  = false;
									    this.getListData();
								    }
							    })
						    }
					    })
				    }
				    else {
					    this.$message({
						    message: '未选择项目！',
						    type: 'warning',
						    duration: 2000
					    })
				    }
			    },100)
		    }else{
					this.$message({
						    message: '未选择项目！',
						    type: 'warning',
						    duration: 2000
					    })
		    }
	    },
	    closeThisDialog(data) {
		    this.isTrue = true
		    this.getListData()
		    
				
				setTimeout(()=>{
					this.declarationDialog = false;
					this.activeName='second';
				},800)
	    },
	    utils() {
		    return utils
	    },
	    isDialog(){
		    // this.$router.push("/login");
		    this.getListData()
	    },
	    handleClick(tab, event) {
		    console.log(tab, event);
	    },
	    getListData(){
		    this.pages.pageIndex = 1
		    this.pages.pageSize = 20
		    this.getDataList()
		    
	    },

      // 列表查询接口
      getDataList(){
          let params = {
              pageIndex: this.pages.pageIndex,
              pageSize: this.pages.pageSize,
              searchKey: this.filters.keyWord,
          }
          this.listLoading = true
        getProjectList(params).then(res => {
              let result = res.data
              if(result.success) {
                  this.tableData = result.response.data
	                if (this.isTrue){
										this.needData = result.response.data[0]
	                }
                  this.pages.dataCount = result.response.dataCount
              }
              this.listLoading = false
          })
      },
	    //获取省份列表
	    getProvinceList(){
		    let params = {
					level:undefined,
			    name:undefined,
			    parentCode:100000,
		    }
		    getAreaDictionaryListWithCode(params).then(res => {
			    let result = res.data
			    if(result.success) {
				    this.options = result.response
				    console.log(this.options,'省份列表')
			    }
		    })
	    },
	    //市
	    getProvinceListfirst(){
		    let params = {
			    level:undefined,
			    name:undefined,
			    parentCode:this.form.queryValue.first?this.form.queryValue.first:null,
		    }
		    getAreaDictionaryListWithCode(params).then(res => {
			    let result = res.data
			    if(result.success) {
				    this.options1 = result.response
				    // console.log(this.provinceList,'省份列表')
			    }
		    })
	    },
	    //区
	    getProvinceListSecond(){
		    let params = {
			    level:undefined,
			    name:undefined,
			    parentCode:this.form.queryValue.second?this.form.queryValue.second:null,
		    }
		    getAreaDictionaryListWithCode(params).then(res => {
			    let result = res.data
			    if(result.success) {
				    this.options2 = result.response
				    // console.log(this.provinceList,'省份列表')
			    }
		    })
	    },
     
	    addProject(){
		    this.query.parentCode = 100000
				this.getProvinceList()
		    this.options = [];
		    this.fileList = [];
				this.needData= undefined;
		    this.isOr = 1;
				this.form = {
					projectAddress:undefined,
					url:undefined,
					projectName: undefined,
					queryValue:{
						first:undefined,
						second:undefined,
						third:undefined,
					},
				}
				// this.registerDialog = true;
				this.projectRegisterDialog = true;
	    },
	    addProjectOther(){
		    this.query.parentCode = 100000
		    this.getProvinceList()
		    this.fileList = [];
				this.needData= undefined;
				this.isOr = 2;
		    this.form = {
			    projectAddress:undefined,
			    url:undefined,
			    projectName: undefined,
			    queryValue:{
				    first:undefined,
				    second:undefined,
				    third:undefined,
			    },
		    }
		    this.projectRegisterDialog = true;
	    },
	    saveNext(){
				if (this.form.projectName == undefined || this.form.projectName == ''){
					this.$message({
						message: '项目名称不能为空！',
						type: 'warning',
						duration: 1000
					})
					return
				}
		    if (this.form.projectAddress == undefined || this.form.projectAddress == ''){
			    this.$message({
				    message: '统一社会信用代码不能为空！',
				    type: 'warning',
				    duration: 1000
			    })
			    return
		    }
		    if (!this.form.queryValue.first&&!this.form.queryValue.second&&!this.form.queryValue.third){
			    this.$message({
				    message: '地区不能为空！',
				    type: 'warning',
				    duration: 1000
			    })
			    return
		    }
		    if (!this.form.address){
			    this.$message({
				    message: '场所地址不能为空！',
				    type: 'warning',
				    duration: 1000
			    })
			    return
		    }
		    let params = {
			    provinceCode :this.form.queryValue.first,
			    cityCode:this.form.queryValue.second,
			    districtCode :this.form.queryValue.third,
		    }
		    if (params.provinceCode&&params.cityCode&&params.districtCode){
			    getContractByAreaCode(params).then(res => {
				    if (res.data.success){
					    this.provinceList = res.data.response
					    console.log(this.provinceList,'省份列表212122')
					    
					    if (res.data.response){
						    let paramease = {
							    contractId :this.provinceList.id,
							    projectAddress :this.form.address,
							    projectCreditCode:this.form.projectAddress
						    }
						    isAllowApplyProject(paramease).then(pre =>{
							    if (pre.data.success){
								    console.log(pre.data.response,'pre')
								    if (pre.data.response){
									    this.openTable()
								    }
							    }else{
								    this.$message({
									    message: pre.data.message,
									    type: 'warning',
									    duration: 2000
								    })
							    }
						    })
					    }else {
								this.$message({
							    message: '该地区未开通AI图审功能！',
							    type: 'warning',
							    duration: 2000
						    })
					    }
				    }
				    
			    })
		    }else {
					this.$message({
						message: '请选择详细地区！',
						type: 'warning',
						duration: 1000,
					})
		    }
	    },
	    openTable(){
		    this.querys = {
			    ...this.form,
			    businessLicense:this.drawingUrl,
			    id:this.provinceList.id
		    }
		    console.log(this.querys,'this.querys')
		    this.activeName = 'first';
		    this.getAreaList()
		    this.isTrue = false
		    this.needData = null
		    this.registerDialog = true;
		    this.projectRegisterDialog = false;
	    },
	    //获取区县列表
	    getAreaList(){
		    let params = {}
		    getContractList(params).then(res => {
			    let result = res.data
			    if(result.success) {
				    this.areaList = result.response
				    console.log(this.areaList,'区县列表')
			    }
		    })
	    },
	    //获取用户信息
	    async getUserInfo () {
		    let params = {}
		    const res = await getAccountInfo(params)
		    if (res.data.success) {
			    this.userInfo = res.data.response
			    this.name = res.data.response.name
		    }
	    },
      // 点击table某一行
      currentChange(val) {
          this.needData = val
	      console.log(val,'val')
      },
      // 分页
      handleCurrentChange(val) {
          this.pages.pageIndex = val;
          this.getDataList();
      },
      handleSizeChange(val){
          this.pages.pageSize = val;
          this.getDataList();
      },

      // 时间格式化
      formatCreateTime: function(row, column) {
          return !row.projectGovPeriod || row.projectGovPeriod == ""
          ? ""
          : util.formatDate.format(new Date(row.projectGovPeriod), "yyyy-MM-dd hh:mm");
      },
      // 表格数据分页条数累计
      indexMethod(index) {
          let currentPage = this.pages.pageIndex
          let pageSize = this.pages.pageSize
          return (index + 1) + (currentPage - 1) * pageSize
      },

    },
    watch: {
    },
    created(){
	      this.getUserInfo()
        this.getListData();
    }
}
</script>

<style lang="stylus" scoped>
.divButton {
	height: 40px;
	width: 20%;
	border-radius: 5px 5px 0 0;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgb(161, 201, 250); /* Normal state: white background */
	color: rgb(67, 146, 244); /* Normal state: black text */
	//border: 1px solid black; /* Optional: Border for normal state */
	cursor: pointer; /* Pointer cursor on hover */
	font-size :20px;
}

/* Style when clicked */
.divButtonActive {
	height: 40px;
	width: 20%;
	border-radius: 5px 5px 0 0;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	//background-color: rgb(161, 201, 250); /* Normal state: white background */
	//color: rgb(67, 146, 244); /* Normal state: black text */
	//border: 1px solid black; /* Optional: Border for normal state */
	cursor: pointer; /* Pointer cursor on hover */
	font-size :20px;
	background-color: white; /* Click state: blue background */
	color: rgb(67, 146, 244); /* Click state: white text */
}


.fileListItem{
	align-items:center;
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
	margin-top :10px;
	padding :5px 20px
	border: 1px solid #ccc;
}
.headerContainer{
	display: flex;
	align-items: center;
	margin: 10px 0 10px 10%;
	justify-content: left;
}
.parent {
	height: 70px;
	background-color: #4392F4;
	display: flex;
	justify-content: left;
	align-items: flex-end;
	margin-bottom: 20px;
}
.child {
	margin-left :10%;
	width: 200px;
	height: 40px;
	background-color: white;
	display: flex;
	justify-content: left;
	align-items: flex-end;
}

.autocomplete-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.autocomplete-container .el-col {
  flex-basis: calc(33.33% - 10px); /* Adjust width to fit three columns in one row */
  margin-bottom: 20px; /* Add space between rows */
}

    .timerSty {
        width: 100px;
    }
    .noTimerSty {
        margin-left: 20px;
    }
    .areaSty {
        width: 300px;
        margin-right: 2px;
    }
    .dialogTitle {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
    }
    .activeSty {
        margin-top: 10px;
    }
    .activeSty .el-form-item {
        margin-bottom: 0px;
    }
    .titleSpeSty {
        font-weight: bold;
    }
    .spacilTimer>>>.el-form-item__label {
        color: red!important;
        font-weight: bold;
    }
    .viewProject>>>.el-dialog__body {
        padding: 0px 20px 30px;
        margin-top: -15px;
    }
</style>
